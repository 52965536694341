.menu-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 30px 30px 30px;
}

.table-divider {
  margin-bottom: 15px;
}

.reject-note-container {
  width: 50%;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-size: 21px !important;
}

h3 {
  font-size: 16.38px !important;
}

h4 {
  font-size: 14px !important;
}

.ant-btn > .anticon > svg {
  vertical-align: unset !important;
}

#company-data p {
  margin-bottom: 0;
}
