h4 {
  font-size: 1rem;
}

@media (min-width: 544px) {
  h4 {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  h4 {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  h4 {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

#select-invoice-container {
  max-width: 768px;
}

#invoice-select-btn:hover .company-icon path {
  stroke: white;
}

#invoice-select-btn:hover .person-icon path {
  stroke: white;
}

#invoice-select-btn:not(:hover) {
  color: #231f20;
}

#invoice-select-btn:hover {
  color: white;
}

.form-label,
.form-floating {
  font-size: 1rem;
}
